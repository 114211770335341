import { create } from "zustand";

export const configurationsStore = create((set) => {
  return {
    // state
    environment: "sandbox",
    FID: "1a27b737-5e9d-4cb2-8da3-70817a67b1c8",

    // actions
    setProperty: (state, value) => {
      set(() => ({ [state]: value }));
    },
  };
});
