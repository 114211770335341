import styled from 'styled-components'

export const Icon = styled.img`
    height: 18px;
    cursor: pointer;
    @media (max-width: 768px) {
        height: 14px;
    }
    
`
export const Label = styled.h5`
    font-family: 'Nexa-Bold';
    font-size: 0.8em;
    text-align: center;
    color: var(--gray-001);
`

export const Title = styled.h5`
    font-family: 'Nexa-Bold';
    font-size: 0.7em;
    text-align: center;
    color: var(--gray-001);
`