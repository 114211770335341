import { postDevice } from "services/device"
import { authorizationStore } from "store/authorization"
import { configurationsStore } from "store/configurations"
import { deviceStore } from "store/device"
import { keyStore } from "store/key"
import { generateCipherAES, generateTokenAES } from "utils/Crypto"

export const getDeviceId = async () => {
  const { id } = deviceStore.getState()

  const deviceId = id

  return deviceId
}

export const getDeviceName = async () => {
  const { name } = deviceStore.getState()

  const deviceName = name

  return deviceName
}

export const createDevice = async () => {
  try {
    const deviceId = await getDeviceId()
    const deviceName = await getDeviceName()

    const sessionToken = generateTokenAES()
    const sessionCipher = await generateCipherAES()

    // Armazena credenciais de sessão
    const { setProperty: setPropertiedKey } = keyStore.getState()
    setPropertiedKey('cipherAES', sessionCipher)
    setPropertiedKey('tokenAES', sessionToken)

    const { FID } = configurationsStore.getState()

    const data = {
      fid: FID,
      id: deviceId,
      ip: '0.0.0.0',
      sessionToken,
      sessionCipher,
      deviceName,
      latitude: 0,
      longitude: 0,
      accuracy: 0,
      fcmToken: '',
    }

    const response = await postDevice(data)

    if (response.status > 300) {
      return {
        success: false,
        ...response.data,
      }
    }

    const { setProperty: setPropertiedAuthorization } =
      authorizationStore.getState()
    setPropertiedAuthorization('authorization', response.data.accessToken)

    return {
      success: true,
      ...response.data,
    }
  } catch (error: any) {
    console.log('ERROR CREATE DEVICE ==> ', error)

    return {
      sucesss: false,
    }
  }
}
