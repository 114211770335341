import moment from 'moment';
import requester from '../requester';
import { IBlockCard, ICards, ICreateCard } from './companyCards.interface';

const serviceCards = {
  activateCard: {
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: '/v1/cards/activation/',
    headers: {
      'Content-Type': 'application/json',
      environmentName: process.env.REACT_APP_ENVIRONMENT_NAME,
      systemName: process.env.REACT_APP_SYSTEM_NAME,
      product: process.env.REACT_APP_PRODUCT,
      Authorization: '{{token}}',
    },
    method: 'post',
  },
  definitiveBlock: {
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: '/v1/cards/block/{{uuid}}',
    headers: {
      'Content-Type': 'application/json',
      environmentName: process.env.REACT_APP_ENVIRONMENT_NAME,
      systemName: process.env.REACT_APP_SYSTEM_NAME,
      product: process.env.REACT_APP_PRODUCT,
      Authorization: '{{token}}',
    },
    method: 'post',
  },
  createCard: {
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: '/v1/cards/virtual/',
    headers: {},
    method: 'post',
  },
  blockCard: {
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: '/v1/cards/block/{{uuid}}',
    headers: {
      'Content-Type': 'application/json',
      environmentName: process.env.REACT_APP_ENVIRONMENT_NAME,
      systemName: process.env.REACT_APP_SYSTEM_NAME,
      authorizationType: 1,
      Authorization: '{{token}}',
    },
    method: 'post',
  },
  cancelAccountCard: {
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: '/v1/account/block/{{uuid}}',
    headers: {
      'Content-Type': 'application/json',
      environmentName: process.env.REACT_APP_ENVIRONMENT_NAME,
      systemName: process.env.REACT_APP_SYSTEM_NAME,
      authorizationType: 1,
      Authorization: '{{token}}',
    },
    method: 'put',
  },
  authorizationHistory: {
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: '/v2/authorization/history/consult/{{uuid}}',
    headers: {
      'Content-Type': 'application/json',
      environmentName: process.env.REACT_APP_ENVIRONMENT_NAME,
      systemName: process.env.REACT_APP_SYSTEM_NAME,
      authorizationType: 3,
      last4digits: '{{last4Digits}}',
      Authorization: '{{token}}',
    },
    method: 'get',
  },
  limitManagement: {
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: '/v1/limit/management/{{uuid}}',
    headers: {
      'Content-Type': 'application/json',
      environmentName: process.env.REACT_APP_ENVIRONMENT_NAME,
      systemName: process.env.REACT_APP_SYSTEM_NAME,
      Authorization: '{{token}}',
    },
    method: 'put',
  },
  getLimit: {
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: '/v1/limit/management/{{uuid}}',
    headers: {
      'Content-Type': 'application/json',
      environmentName: process.env.REACT_APP_ENVIRONMENT_NAME,
      systemName: process.env.REACT_APP_SYSTEM_NAME,
      last4digits: '{{last4digits}}',
      Authorization: '{{token}}',
    },
    method: 'get',
  },
  twoWay: {
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: '/v1/cards/issue/2way/{{uuid}}',
    headers: {
      'Content-Type': 'application/json',
      environmentName: process.env.REACT_APP_ENVIRONMENT_NAME,
      systemName: process.env.REACT_APP_SYSTEM_NAME,
      Authorization: '{{token}}',
    },
    method: 'post',
  },
  passwordUnlock: {
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: '/v1/cards/password/reset/{{uuid}}',
    headers: {
      'Content-Type': 'application/json',
      environmentName: process.env.REACT_APP_ENVIRONMENT_NAME,
      systemName: process.env.REACT_APP_SYSTEM_NAME,
      authorizationType: 1,
      Authorization: '{{token}}',
    },
    method: 'post',
  },
  updateClientInformations: {
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: '/v1/client/account/{{uuid}}',
    headers: {
      'Content-Type': 'application/json',
      environmentName: process.env.REACT_APP_ENVIRONMENT_NAME,
      systemName: process.env.REACT_APP_SYSTEM_NAME,
      authorizationType: 1,
      Authorization: '{{token}}',
    },
    method: 'put',
  },
  deactivationCard: {
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: '/v1/cards/deactivation/{{uuid}}',
    headers: {
      'Content-Type': 'application/json',
      environmentName: process.env.REACT_APP_ENVIRONMENT_NAME,
      systemName: process.env.REACT_APP_SYSTEM_NAME,
      Authorization: '{{token}}',
    },
    method: 'post',
  },
  activationCard: {
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: '/v1/cards/activation/{{uuid}}',
    headers: {
      'Content-Type': 'application/json',
      environmentName: process.env.REACT_APP_ENVIRONMENT_NAME,
      systemName: process.env.REACT_APP_SYSTEM_NAME,
      Authorization: '{{token}}',
    },
    method: 'post',
  },
  cardData: {
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: '/v2/card/consult/{{uuid}}',
    headers: {
      'Content-Type': 'application/json',
      environmentName: process.env.REACT_APP_ENVIRONMENT_NAME,
      systemName: process.env.REACT_APP_SYSTEM_NAME,
      Authorization: '{{token}}',
      last4digits: '{{last4digits}}',
      product: process.env.REACT_APP_PRODUCT,
    },
    method: 'get',
  },
  consultCard: {
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: '/v2/card/consult/{{uuid}}',
    headers: {
      'Content-Type': 'application/json',
      environmentName: process.env.REACT_APP_ENVIRONMENT_NAME,
      systemName: process.env.REACT_APP_SYSTEM_NAME,
      Authorization: '{{token}}',
      last4digits: '{{last4digits}}',
      product: process.env.REACT_APP_PRODUCT,
    },
    method: 'get',
  },
  invoicesDueDate: {
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: '/v2/invoice/duedate/{{uuid}}',
    headers: {
      'Content-Type': 'application/json',
      environmentName: process.env.REACT_APP_ENVIRONMENT_NAME,
      Authorization: '{{token}}',
      last4digits: '{{last4digits}}',
      systemName: process.env.REACT_APP_SYSTEM_NAME,
    },
    method: 'get',
  },
  invoicesTransacion: {
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: '/v2/invoice/transactions/{{uuid}}',
    headers: {
      'Content-Type': 'application/json',
      environmentName: process.env.REACT_APP_ENVIRONMENT_NAME,
      Authorization: '{{token}}',
      dueDate: '{{dueDate}}',
      last4digits: '{{last4digits}}',
      systemName: process.env.REACT_APP_SYSTEM_NAME,
    },
    method: 'get',
  },
  accountCards: {
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: '/v1/cards/account/{{uuid}}',
    headers: {
      Authorization: '{{token}}',
      environmentName: process.env.REACT_APP_ENVIRONMENT_NAME,
      org: process.env.REACT_APP_ORG_NUMBER,
      systemName: process.env.REACT_APP_SYSTEM_NAME,
    },
    method: 'get',
  },
};

function getToken() {
  const authenticateJson = localStorage.getItem('persist:Fidis:');
  const authenticate = JSON.parse(authenticateJson || '');
  const { token } = JSON.parse(authenticate.authenticate || '');

  return token;
}

export async function postActivateCard(body: ICards) {
  let { activateCard }: { activateCard: any } = serviceCards;
  activateCard.headers.Authorization = getToken();

  const response = await requester(activateCard, body);
  return response;
}

export async function postDefinitiveBlock(data: {
  last4digits: string;
  descriptionOccurrence: string;
  blockingReason: string;
  uuid: string;
}) {
  let { definitiveBlock }: { definitiveBlock: any } = serviceCards;
  definitiveBlock.headers.Authorization = getToken();
  const { last4digits, descriptionOccurrence, blockingReason, uuid } = data;

  const endpoint = definitiveBlock.endpoint.replace('{{uuid}}', uuid);
  const headers = { ...definitiveBlock, endpoint };

  const body = {
    descriptionOccurrence,
    last4digits,
    blockingReason,
  };

  const response = await requester(headers, body);
  return response;
}

export async function postCreateCard(body: ICreateCard) {
  let { createCard }: { createCard: any } = serviceCards;
  createCard.headers.Authorization = getToken();

  const response = await requester(createCard, body);
  return response;
}

export async function postBlockCard(uuid: string, body: IBlockCard) {
  let { blockCard }: { blockCard: any } = serviceCards;
  blockCard.headers.Authorization = getToken();

  const endpoint = blockCard.endpoint.replace('{{uuid}}', uuid);
  const headers = { ...blockCard, endpoint };

  const response = await requester(headers, body);
  return response;
}

export async function putCancelAccountCard(last4Digits: string, uuid: string) {
  let { cancelAccountCard }: { cancelAccountCard: any } = serviceCards;
  cancelAccountCard.headers.Authorization = getToken();

  const endpoint = cancelAccountCard.endpoint.replace('{{uuid}}', uuid);
  const headers = { ...cancelAccountCard, endpoint };

  const response = await requester(headers, { last4Digits });
  return response;
}

export async function getAuthorizationHistory(
  last4Digits: string,
  uuid: string
) {
  try {
    let { authorizationHistory }: { authorizationHistory: any } = serviceCards;
    authorizationHistory.headers.Authorization = getToken();
    const { headers } = authorizationHistory;

    const last4digits = headers.last4digits.replace(
      '{{last4Digits}}',
      last4Digits
    );

    const headerValues = { ...headers, last4digits };
    const newHeaders = { ...authorizationHistory, headers: headerValues };

    const endpoint = authorizationHistory.endpoint.replace('{{uuid}}', uuid);
    const header = { ...newHeaders, endpoint };

    const response = await requester(header);
    const { data, status } = response;

    if (status === 200) {
      let { register } = data;

      register = register.filter(
        (reg: { descDrop: string }) => reg.descDrop.toUpperCase() === 'NORMAL'
          || reg.descDrop.toUpperCase() === 'NEGADA'
          || reg.descDrop.toUpperCase() === 'ONLINE'
          || reg.descDrop.toUpperCase() === 'CANCELADA'
          || reg.descDrop.toUpperCase() === 'APROVADA'
      );
      register = register.filter(
        (reg: { transactionType: string }) => reg.transactionType !== ''
      );

      register = register.map((reg: any) => {
        if (typeof reg.tmsInclH === 'string') {
          reg.tmsInclH = moment(
            reg.tmsInclH.split(',')[0],
            'DD/MM/YYYY HH:mm:ss'
          );
        }
        return reg;
      });

      return register;
    }

    return [];
  } catch (error: any) {
    return error.response.data.register;
  }
}

export async function putLimitManagement(uuid: string, body: any) {
  let { limitManagement }: { limitManagement: any } = serviceCards;
  limitManagement.headers.Authorization = getToken();

  const endpoint = limitManagement.endpoint.replace('{{uuid}}', uuid);
  const headers = { ...limitManagement, endpoint };

  const response = await requester(headers, body);
  return response;
}

export async function getLimitManagement(uuid: string, last4Digits: string) {
  let { getLimit }: { getLimit: any } = serviceCards;
  getLimit.headers.Authorization = getToken();
  const { headers } = getLimit;

  const last4digits = headers.last4digits.replace(
    '{{last4digits}}',
    last4Digits
  );
  const headerValues = { ...headers, last4digits };
  const newHeaders = { ...getLimit, headers: headerValues };

  const endpoint = getLimit.endpoint.replace('{{uuid}}', uuid);
  const header = { ...newHeaders, endpoint };

  const response = await requester(header);
  return response;
}

export async function postTwoWay(data: {
  last4digits: string;
  reasonReissueCard: string;
  embossName: string;
  deficiencyVisualdescription: string;
  line4Emboss?: string;
  uuid: string;
}) {
  let { twoWay }: { twoWay: any } = serviceCards;
  twoWay.headers.Authorization = getToken();
  const {
    last4digits,
    reasonReissueCard,
    embossName,
    deficiencyVisualdescription,
    line4Emboss,
    uuid,
  } = data;

  const endpoint = twoWay.endpoint.replace('{{uuid}}', uuid);
  const headers = { ...twoWay, endpoint };

  const body = {
    last4digits,
    reasonReissueCard,
    embossName,
    deficiencyVisualdescription,
    line4Emboss,
  };

  const response = await requester(headers, body);
  return response;
}

export async function postPasswordUnlock(
  uuid: string,
  body: { last4digits: string; userId: string }
) {
  let { passwordUnlock }: { passwordUnlock: any } = serviceCards;
  passwordUnlock.headers.Authorization = getToken();

  const endpoint = passwordUnlock.endpoint.replace('{{uuid}}', uuid);
  const headers = { ...passwordUnlock, endpoint };

  const response = await requester(headers, body);
  return response;
}

export async function updateClientInformations(
  uuid: string,
  body: {
    last4digits: string;
    motherName: string;
    name: string;
    birthDate: string;
    email: string;
    phoneNumber: string;
    dddPhone: string;
    cellPhoneNumber: string;
    dddCellPhone: string;
    gender: string;
  }
) {
  let {
    updateClientInformations,
  }: { updateClientInformations: any } = serviceCards;
  updateClientInformations.headers.Authorization = getToken();

  const endpoint = updateClientInformations.endpoint.replace('{{uuid}}', uuid);
  const headers = { ...updateClientInformations, endpoint };

  const response = await requester(headers, body);
  return response;
}

export async function PostDeactivationCard(uuid: string, last4digits: string) {
  let { deactivationCard }: { deactivationCard: any } = serviceCards;
  deactivationCard.headers.Authorization = getToken();

  const endpoint = deactivationCard.endpoint.replace('{{uuid}}', uuid);
  const headers = { ...deactivationCard, endpoint };

  const response = await requester(headers, { last4digits });
  return response;
}

export async function PostActivationCard(uuid: string, last4digits: string) {
  let { activationCard }: { activationCard: any } = serviceCards;
  activationCard.headers.Authorization = getToken();

  const endpoint = activationCard.endpoint.replace('{{uuid}}', uuid);
  const headers = { ...activationCard, endpoint };

  const response = await requester(headers, { last4digits, action: 'A' });
  return response;
}

export async function getCardData(uuid: string, last4digits: string) {
  let { cardData }: { cardData: any } = serviceCards;
  cardData.headers.Authorization = getToken();
  cardData.headers.last4digits = last4digits;
  const endpoint = cardData.endpoint.replace('{{uuid}}', uuid);
  const headers = { ...cardData, endpoint };

  const response = await requester(headers);
  return response;
}

export async function consultCard(uuid: string, last4digits: string) {
  let { consultCard }: { consultCard: any } = serviceCards;
  consultCard.headers.Authorization = getToken();
  consultCard.headers.last4digits = last4digits;
  const endpoint = consultCard.endpoint.replace('{{uuid}}', uuid);
  const headers = { ...consultCard, endpoint };

  const response = await requester(headers);
  return response;
}

export async function getInvoicesDueDate(uuid: string, last4digits: string) {
  let { invoicesDueDate }: { invoicesDueDate: any } = serviceCards;
  invoicesDueDate.headers.Authorization = getToken();
  invoicesDueDate.headers.last4digits = last4digits;
  const endpoint = invoicesDueDate.endpoint.replace('{{uuid}}', uuid);
  const headers = { ...invoicesDueDate, endpoint };

  const response = await requester(headers);
  return response;
}

export async function getInvoicesTransactions(
  uuid: string,
  last4digits: string,
  dueDate: string
) {
  let { invoicesTransacion }: { invoicesTransacion: any } = serviceCards;
  invoicesTransacion.headers.Authorization = getToken();
  invoicesTransacion.headers.last4digits = last4digits;
  invoicesTransacion.headers.dueDate = dueDate;
  const endpoint = invoicesTransacion.endpoint.replace('{{uuid}}', uuid);
  const headers = { ...invoicesTransacion, endpoint };

  let { status, data } = await requester(headers);

  if (status === 200) {
    data.transaction = data.transaction.filter(
      (transaction: any) =>
        transaction.abbreviationGroup === 'CRE' ||
        transaction.abbreviationGroup === 'DEN' ||
        transaction.abbreviationGroup === 'PGT' ||
        transaction.abbreviationGroup === 'DEI' ||
        transaction.abbreviationGroup === 'DEB'
    );

    data.transaction = data.transaction.map((transaction: any) => {
      if (typeof transaction.date === 'string') {
        transaction.date = moment(transaction.date, 'YYYYMMDD').add(
          '1',
          'minute'
        );
      }
      return transaction;
    });

    return data;
  }
}

export async function getAccountCards(uuid: string) {
  let { accountCards }: { accountCards: any } = serviceCards;
  accountCards.headers.Authorization = getToken();
  const endpoint = accountCards.endpoint.replace('{{uuid}}', uuid);
  const headers = { ...accountCards, endpoint };

  const response = await requester(headers);
  return response;
}
