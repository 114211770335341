import Loading from 'components/Loading/Loading.component';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { putAddress } from 'store/modules/client/client.endpoints';
import { updateClientInformations } from 'store/modules/companyCards/companyCards.endpoints';
import { setAccounts } from 'store/modules/relationship/relationship.actions';
import { getAccounts } from 'store/modules/relationship/relationship.endpoints';
import { getCep } from 'utils/ViaCep';
import CarrierDataScreen from './CarrierData.screen';

interface ICurrentCard {
  uuid: string;
  last4Digits: string;
}
interface ICurrentAccount {
  name: string;
  birthDate: string;
  motherName: string;
  email: string;
  telephone: string;
  cellPhone: string;
  dddCellPhone: string;
  showFormInit: string;
  setShowFormInit: string;
  cep: string;
  number: string;
  complement: string;
  address: string;
  state: string;
  city: string;
  district: string;
  buttonLoading: string;
  setButtonLoading: string;
  ddd: string;
  gender: string;
}

function CarrierDataController({
  currentCard,
  companyCnpj,
  currentAccount,
  companyRelationcode,
}: {
  currentCard: ICurrentCard;
  companyCnpj: string;
  currentAccount: any;
  companyRelationcode: string;
}) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [showFormInit, setShowFormInit] = useState(true);
  const [name, setName] = useState(currentAccount.clientNameHolder);
  const [birthDate, setBirthDate] = useState('');
  const [motherName, setMotherName] = useState(currentAccount.motherName);
  const [email, setEmail] = useState(currentAccount.mail);
  const [telephone, setTelephone] = useState(currentAccount.phoneNumber);
  const [cellPhone, setCellPhone] = useState(currentAccount.cellPhone);
  const [dddCellPhone, setDddCellPhone] = useState(currentAccount.dddCellPhone);
  const [cep, setCep] = useState(
    currentAccount.zipCode
      .replace(/\D/g, '')
      .replace(/^(\d{5})(\d{3})/g, '$1-$2')
  );
  const [number, setNumber] = useState(currentAccount.addressNumber);
  const [complement, setComplement] = useState(
    currentAccount.addressComplement
  );
  const [address, setAddress] = useState(currentAccount.address);
  const [state, setState] = useState(currentAccount.state);
  const [city, setCity] = useState(currentAccount.city);
  const [district, setDistrict] = useState(currentAccount.district);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [ddd, setDdd] = useState(currentAccount.ddd);
  const [gender, setGender] = useState(currentAccount.gender);

  const dispatch = useDispatch();

  useEffect(() => {
    getInfoAccount();
  }, []);

  const getInfoAccount = async () => {
    try {
      const birthDate = currentAccount.birthDate;
      setBirthDate(moment(birthDate, 'DDMMYYYY').format('YYYY-MM-DD'));

      setLoading(false);
    } catch (e) {
      setError('Dados do portador não encontrado!');
      setLoading(false);
    }
  };

  const handlerShowForm = () => {
    setShowFormInit(!showFormInit);
  };

  const findCepAndSetValues = async (cep: string) => {
    cep = cep.replace(/\D/g, '').replace(/^(\d{5})(\d{3})/g, '$1-$2');
    setCep(cep);
    if (cep.length < 9) {
      return;
    }

    const response = await getCep(cep);

    const { bairro, complemento, logradouro, localidade, uf } = response;
    setDistrict(bairro);
    setComplement(complemento);
    setAddress(logradouro);
    setCity(localidade);
    setState(uf);
  };

  const IAddress = async () => {
    try {
      if (!cep || !number || !address || !state || !city || !district) {
        return alert('Verifique se todos os campos foram preenchidos!');
      }
      if (cep.length < 8) {
        return alert('O campo CEP é inválido.');
      }
      const body = {
        addressHome: address,
        addressHomeNumber: number,
        addressHomeComplement: complement,
        district: district.substring(0, 15),
        city,
        state,
        zipCode: cep.split('-').join(''),
      };
      setButtonLoading(true);
      await putAddress(body, currentCard.uuid);
      alert('Endereço atualizado com sucesso!');
      setTimeout(() => {
        setButtonLoading(false);
      }, 1000);
    } catch (error: any) {
      setButtonLoading(false);

      alert('Ocorreu um erro ao realizar a atualização de endereço.');
    }
  };

  const handleUpdateClient = async () => {
    try {
      if (
        !motherName ||
        !name ||
        !telephone ||
        !ddd ||
        !cellPhone ||
        !dddCellPhone ||
        !email
      ) {
        return alert('Preencha todos os campos obrigatórios');
      }
      const birthDateSplit = birthDate.split('-');
      const newBirthDate =
        birthDateSplit[2] + birthDateSplit[1] + birthDateSplit[0];

      setButtonLoading(true);

      await updateClientInformations(currentCard.uuid, {
        last4digits: currentCard.last4Digits,
        motherName,
        name,
        birthDate: newBirthDate,
        email,
        phoneNumber: telephone,
        dddPhone: ddd,
        cellPhoneNumber: cellPhone,
        dddCellPhone,
        gender,
      });
      const response = await getAccounts(companyCnpj, companyRelationcode);

      dispatch(setAccounts(response));
      setButtonLoading(false);
      alert('Informações do cliente atualizadas com sucesso!');
    } catch (error: any) {
      setButtonLoading(false);
      alert('Não foi possível realizar alteração dos dados do cliente.');
    }
  };

  const handlers = {
    name,
    birthDate,
    motherName,
    email,
    telephone,
    cellPhone,
    showFormInit,
    setShowFormInit,
    cep,
    setCep,
    number,
    setNumber,
    complement,
    setComplement,
    address,
    setAddress,
    state,
    setState,
    city,
    setCity,
    handlerShowForm,
    error,
    district,
    setDistrict,
    IAddress,
    buttonLoading,
    setButtonLoading,
    findCepAndSetValues,
    dddCellPhone,
    setDddCellPhone,
    ddd,
    setDdd,
    setTelephone,
    handleUpdateClient,
    setName,
    setBirthDate,
    setMotherName,
    setEmail,
    setCellPhone,
    gender,
    setGender,
  };

  return (
    <>
      {loading ? (
        <Loading size={40} icon={true} />
      ) : (
        <CarrierDataScreen handlers={handlers} />
      )}
    </>
  );
}
const mapStateToProps = (state: any) => ({
  currentCard: state.relationship.currentCard,
  currentAccount: state.relationship.currentAccount,
  companyCnpj: state.company.cnpj,
  companyRelationcode: state.company.relationCode,
});
export default connect(mapStateToProps)(CarrierDataController);
