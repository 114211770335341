import { api } from "utils/Api"

export const postDevice = async (data: unknown) => {
  try {
    const response = await api({}).post('/devices', data)

    return response
  } catch (error: any) {
    console.log('ERROR IN REGISTER DEVICE ==> ', error)

    return error.response
  }
}
