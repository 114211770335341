import requester from '../requester';
import { ISignIn } from './authenticate.interface';

const serviceAuthenticate = {
  signIn: {
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: '/v0/portal/usuario/login',
    headers: {
      'Content-Type': 'application/json',
      environmentName: process.env.REACT_APP_ENVIRONMENT_NAME,
      systemName: process.env.REACT_APP_SYSTEM_NAME,
      Authorization: '{{token}}',
    },
    method: 'post',
  },
  forgotPassword: {
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: '/v0/portal/usuario/recuperarsenha',
    headers: {
      environmentName: process.env.REACT_APP_ENVIRONMENT_NAME,
      systemName: process.env.REACT_APP_SYSTEM_NAME,
      'Content-Type': 'application/json',
      Authorization: '{{token}}',
    },
    method: 'post',
  },
  firstAccess: {
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: '/v0/portal/usuario/primeiroacesso',
    headers: {
      environmentName: process.env.REACT_APP_ENVIRONMENT_NAME,
      systemName: process.env.REACT_APP_SYSTEM_NAME,
      'Content-Type': 'application/json',
      Authorization: '{{token}}',
    },
    method: 'put',
  },
};

function getToken() {
  const authenticateJson = localStorage.getItem('persist:Fidis:');
  const authenticate = JSON.parse(authenticateJson || '');
  const token = `Bearer ${authenticate?.tokenBackEnd?.OAuth2AccessToken?.access_token}`;

  return token;
}

export async function postSignIn(body: ISignIn) {
  let { signIn }: { signIn: any } = serviceAuthenticate;
  signIn.headers.Authorization = getToken();

  const { data } = await requester(signIn, body);
  return data;
}

export async function postForgotPassword({
  login,
  confirmacao,
}: {
  login?: string;
  confirmacao?: string;
}) {
  let { forgotPassword }: { forgotPassword: any } = serviceAuthenticate;

  forgotPassword.headers.Authorization = getToken();

  const { data } = await requester(forgotPassword, { login, confirmacao });
  return data;
}

export async function postFirstAccess({
  login,
  novaSenha,
  confirmarNovaSenha,
}: {
  login: string;
  novaSenha: string;
  confirmarNovaSenha: string;
}) {
  let { firstAccess }: { firstAccess: any } = serviceAuthenticate;
  firstAccess.headers.Authorization = getToken();

  const { data } = await requester(firstAccess, {
    login,
    novaSenha,
    confirmarNovaSenha,
  });
  return data;
}
