import { api } from 'utils/Api';

const requester = async (service: any, data = {}) => {
  let { baseURL, endpoint, headers = {}, method } = service;

  if (headers.Authorization && headers.Authorization.length > 0) {
    delete headers.Authorization
  }

  const response = await api({}).post('/proxy', {
    method,
    url: baseURL + endpoint,
    headers: {
      'x-supplier': 'orbitall',
      ...headers,
    },
    data
  })

  console.log('RESPONSE REQUESTER: ', response);

  return response;
};

export default requester;
