import { useEffect, useState } from 'react';
import InvoicesScreen from './Invoices.screen';

import { connect } from 'react-redux';

import {
  getInvoices,
  getInvoicesDueDate,
} from 'store/modules/invoices/invoices.endpoints';
import { getCardByDocumentNumber } from 'store/modules/relationship/relationship.endpoints';

function InvoicesController({
  companyCnpj,
  accounts
}: {
  companyCnpj: string;
  accounts: any
}) {
  const mainAccount = accounts.subAccounts.find((a: any) => a.account === accounts.mainAccount)

  const document = mainAccount && mainAccount.documentNumber || accounts.subAccounts[0].documentNumber
  const last4digits = mainAccount && mainAccount.last4Digits || accounts.subAccounts[0].last4Digits

  const [emptyInvoice, setEmptyInvoice] = useState(true);
  const [loader, setLoader] = useState(true);
  const [dueDate, setDueDate] = useState('');
  const [dueDateList, setDueDateList] = useState('');
  const [totalInvoiceValue, setTotalInvoiceValue] = useState('');
  const [digitableLine, setDigitableLine] = useState('');
  const [previousInvoiceValue, setPreviousInvoiceValue] = useState('');
  const [totalPaymentValue, setTotalPaymentValue] = useState('');
  const [bsPaymentMinNotParcel, setBsPaymentMinNotParcel] = useState('');
  const [
    expenditureInternationalValue,
    setExpenditureInternationalValue,
  ] = useState('');
  const [totalDebitNationalValue, setTotalDebitNationalValue] = useState('');
  const [
    withdrawalCashLimitInternationalValue,
    setWithdrawalCashLimitInternationalValue,
  ] = useState('');
  const [
    withdrawalCashLimitNationalValue,
    setWithdrawalCashLimitNationalValue,
  ] = useState('');
  const [financialBurdenValue, setFinancialBurdenValue] = useState('');
  const [paidNotIntegralValue, setPaidNotIntegralValue] = useState('');

  useEffect(() => {
    getInvoicesData();
  }, []);

  const getInvoicesData = async () => {
    try {
      const response = await getCardByDocumentNumber(document);
      const { uuid } = response.data?.cardsResult
        .find((c: any) => c.last4Digits2Way === last4digits || c.last4Digits === last4digits)

      const dueDateResponse = await getInvoicesDueDate(
        last4digits,
        uuid
      );

      setDueDateList(dueDateResponse.data?.listInvoiceDueDate);

      const { data } = await getInvoices(
        dueDateResponse.data?.listInvoiceDueDate[0]?.invoiceDueDate,
        companyCnpj
      );

      setEmptyInvoice(false);
      setDueDate(data.dueDate);
      setTotalInvoiceValue(data.totalInvoiceValue);
      setDigitableLine(data.digitableLine);
      setPreviousInvoiceValue(data.previousInvoiceValue);
      setTotalPaymentValue(data.totalPaymentValue);
      setBsPaymentMinNotParcel(data.bsPaymentMinNotParcel);
      setExpenditureInternationalValue(data.expenditureInternationalValue);
      setTotalDebitNationalValue(data.totalDebitNationalValue);
      setWithdrawalCashLimitInternationalValue(
        data.withdrawalCashLimitInternationalValue
      );
      setWithdrawalCashLimitNationalValue(
        data.withdrawalCashLimitNationalValue
      );
      setFinancialBurdenValue(data.financialBurdenValue);
      setPaidNotIntegralValue(data.paidNotIntegralValue);
      setLoader(false);
    } catch (error: any) {
      setEmptyInvoice(true);
      setLoader(false);
      console.log(error.response, 'error request');
    }
  };

  const handleSelectDueDate = async (dateSelected: string) => {
    try {
      const { data } = await getInvoices(dateSelected, companyCnpj);

      setEmptyInvoice(false);
      setTotalInvoiceValue(data.totalInvoiceValue);
      setDigitableLine(data.digitableLine);
      setPreviousInvoiceValue(data.previousInvoiceValue);
      setTotalPaymentValue(data.totalPaymentValue);
      setBsPaymentMinNotParcel(data.bsPaymentMinNotParcel);
      setExpenditureInternationalValue(data.expenditureInternationalValue);
      setTotalDebitNationalValue(data.totalDebitNationalValue);
      setWithdrawalCashLimitInternationalValue(
        data.withdrawalCashLimitInternationalValue
      );
      setWithdrawalCashLimitNationalValue(
        data.withdrawalCashLimitNationalValue
      );
      setFinancialBurdenValue(data.financialBurdenValue);
      setPaidNotIntegralValue(data.paidNotIntegralValue);
    } catch (error: any) {
      setEmptyInvoice(true);
    }
  };

  const handlers = {
    dueDate,
    dueDateList,
    totalInvoiceValue,
    digitableLine,
    previousInvoiceValue,
    totalPaymentValue,
    bsPaymentMinNotParcel,
    expenditureInternationalValue,
    totalDebitNationalValue,
    withdrawalCashLimitInternationalValue,
    withdrawalCashLimitNationalValue,
    handleSelectDueDate,
    emptyInvoice,
    financialBurdenValue,
    paidNotIntegralValue,
    loader
  };

  return <InvoicesScreen handlers={handlers} />;
}

const mapStateToProps = (state: any) => ({
  companyCnpj: state.company.cnpj,
  accounts: state.relationship.accounts
});

export default connect(mapStateToProps)(InvoicesController);
