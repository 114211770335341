import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

const PrivateRoute: any = ({
  token,
  component: Component,
  ...rest
}: {
  token: string;
  component: any;
  exact?: boolean;
  path?: string;
}) => {
  return (
    <Route
      {...rest}
      render={(props: any) =>
        token ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        )
      }
    />
  );
};

const mapStateToProps = (state: any) => ({
  token: state.authenticate.user,
});
export default connect(mapStateToProps)(PrivateRoute);
