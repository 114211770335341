import { create } from "zustand";

export const deviceStore = create()((set) => {
  return {
    // state
    id: "",
    name: "",

    // actions
    setProperty: (state, value) => {
      set(() => ({ [state]: value }));
    },
  };
});
