import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
  align-content: center;
  padding: 2px;
  margin-bottom: 15px;
`;

export const Label = styled.span`
  font-family: 'Helvetica-Medium';
  font-size: 14px;
  text-align: center;
  color: var(--gray-001);

  @media (max-width: 576px) {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: -webkit-box;
    width: 60px;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  @media (max-width: 768px) {
    font-size: 0.5em;
  }
`;

export const Title = styled.h5`
  font-family: 'Nexa-Bold';
  font-size: 1em;
  text-align: center;
  color: var(--gray-001);
  @media (max-width: 768px) {
    font-size: 0.8em;
  }
`;

export const Header = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

export const Separator = styled.div`
  display: flex;
  flex: 1;
  margin-right: 1px;
  justify-content: center;
  align-items: center;
  height: 50px;
  flex-direction: row;
  padding: 0 5px;
`;

export const Icon = styled.img`
  height: 18px;
  cursor: pointer;
  @media (max-width: 768px) {
    height: 14px;
    margin-right: 10px;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 4px;
`;

export const ButtonTitle = styled.h5`
  font-family: 'Helvetica-Medium';
  font-size: 14px;
  color: var(--gray-001);
  text-align: right;
`;

export const Left = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

export const Spacement = styled.div`
  flex: 1;
`;
export const Right = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

export const Middle = styled.div`
  flex: 3;
  align-self: center;
`;

export const Circular = styled.div`
  height: 10px;
  width: 10px;
  border-radius: 5px;
  margin-left: 5px;
  margin-bottom: 4px;
`;
export const Button = styled.button`
  width: 100%;
  height: 35px;
  border-radius: 30px;
  background-color: #00a4a1;
  border: none;
  cursor: pointer;
  color: #fff;
  &:hover {
    background-color: #00a4a1;
  }
  &:active {
    background-color: #00ccc9;
  }
`;
export const ButtonOutline = styled.button`
  width: 100%;
  height: 35px;
  border-radius: 30px;
  border: 2px solid #00a4a1;
  background-color: transparent;
  cursor: pointer;
  color: #00a4a1;
`;
