import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { sendOccurrence } from 'services/Occurrence';
import {
  getCardData,
  postTwoWay,
} from 'store/modules/companyCards/companyCards.endpoints';
import RequestDuplicateScreen from './Request2nd.screen';

function Request2ndController({
  currentAccount,
  currentCard,
  user,
}: {
  currentAccount: { embossName: string; indVisualDeficiency: string };
  currentCard: { uuid: string; last4Digits: string };
  user: any;
}) {
  const [reasonReissueCard, setReasonReissueCard] = useState('');
  const [embossName, setEmbossName] = useState('');
  const [
    deficiencyVisualdescription,
    setDeficiencyVisualdescription,
  ] = useState('');
  const [loading, setLoading] = useState(false);

  const { uuid, last4Digits } = currentCard;

  useEffect(() => {
    getCurrentCardData();
  }, []);

  async function getCurrentCardData() {
    const { data } = await getCardData(uuid, last4Digits);
    setEmbossName(data.embossName);
    setDeficiencyVisualdescription(data.indVisualDeficiency || 'N');
  }

  const handleRequest2nd = async () => {
    try {
      if (!reasonReissueCard) {
        return alert('É obrigatório preencher o motivo da Segunda Via!');
      }

      const { last4Digits: last4digits, uuid: cardId } = currentCard;
      const modalityType = 'ISSUE_CARD_2_WAY';
      const openingText = `User ${user.id} - ${user.usuario} requesting a 2 way of the card with last4digits: ${last4digits} and cardId: ${cardId}`;

      sendOccurrence(cardId, last4digits, modalityType, openingText);

      const body = {
        uuid,
        last4digits: last4Digits,
        reasonReissueCard,
        embossName,
        deficiencyVisualdescription,
      };
      setLoading(true);
      await postTwoWay(body);

      alert('Segunda via solicitada com sucesso!');
      setLoading(false);
    } catch (error: any) {
      alert('No momento não foi possivel solícitar a seguda via do cartão.');
      setLoading(false);
    }
  };

  const handlers = {
    currentCard,
    setReasonReissueCard,
    handleRequest2nd,
    loading,
    setLoading,
  };

  return <RequestDuplicateScreen handlers={handlers} />;
}

const mapStateToProps = (state: any) => ({
  currentAccount: state.relationship.currentAccount,
  currentCard: state.relationship.currentCard,
  user: state.authenticate.user,
});
export default connect(mapStateToProps)(Request2ndController);
