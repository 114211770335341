import Loading from 'components/Loading/Loading.component';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import history from 'services/history';
import {
  postDefinitiveBlock,
  putCancelAccountCard,
} from 'store/modules/companyCards/companyCards.endpoints';
import {
  setCurrentCardStatus,
  updateAccountStatus,
} from 'store/modules/relationship/relationship.actions';
import CancelCardScreen from './CancelCard.screen';
interface ICurrentCard {
  uuid: string;
  last4Digits: string;
}

function CancelCardController({
  currentCard,
  accountStatusDescription,
  accountId,
}: {
  currentCard: ICurrentCard;
  accountStatusDescription: string;
  accountId: string;
}) {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(false);
  }, []);

  const handleCancelCard = async () => {
    try {
      const { last4Digits, uuid } = currentCard;
      setLoading(true);
      await putCancelAccountCard(last4Digits, uuid);
      const body = {
        last4digits: last4Digits,
        descriptionOccurrence: '',
        blockingReason: 'O',
        uuid,
      };
      await postDefinitiveBlock(body);
      alert('Conta Cartão cancelada com sucesso!');

      dispatch(setCurrentCardStatus('Blocked'));

      dispatch(updateAccountStatus(accountId, 'inactive'));

      setLoading(false);

      return history.push('/home');
    } catch (error: any) {
      setLoading(false);
      alert('Ocorreu um erro ao cancelar o cartão!');
    }
  };

  const handlers = {
    handleCancelCard,
    accountStatusDescription,
  };

  return (
    <>
      {loading ? (
        <Loading size={40} icon={true} />
      ) : (
        <CancelCardScreen handlers={handlers} />
      )}
    </>
  );
}

const mapStateToProps = (state: any) => ({
  currentCard: state.relationship.currentCard,
  accountStatusDescription:
    state.relationship.currentAccount.accountStatusDescription,
  accountId: state.relationship.currentAccount.accountNumber,
});
export default connect(mapStateToProps)(CancelCardController);
