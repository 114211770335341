import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  flex: 1;
  flex-direction: column;
`;

export const Title = styled.h3`
  font-family: 'Nexa-Heavy';
  color: var(--gray-001);
  margin-bottom: 30px;
  margin-top: 30px;
`;

export const Label = styled.label`
  font-family: 'Helvetica-Normal';
  color: var(--gray-001);
  margin-bottom: 30px;
`;
export const Button = styled.button`
  width: 100%;
  height: 35px;
  border-radius: 30px;
  background-color: var(--primary);
  border: none;
  cursor: pointer;
  color: #fff;
  font-family: 'Helvetica-Normal';
  &:hover {
    background-color: var(--primary);
  }
  &:active {
    background-color: var(--primary-03);
  }
`;

export const Left = styled.div`
  flex: 1;
  margin-right: 20px;
`;
export const Right = styled.div`
  flex: 1;

  @media (max-width: 768px) {
    display: none;
  }
`;
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

export const RowInput = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
`;
export const LeftInput = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  margin-right: 15px;
`;
export const MiddleInput = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  margin-right: 15px;
`;
export const RightInput = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 2;
`;
