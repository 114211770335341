import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  postSignIn
} from 'store/modules/authenticate/authenticate.endpoints';
import { v4 as uuidv4 } from "uuid";
import SignInScreen from './signIn.screen';

import { createDevice, getKey } from "actions";

import { configurationsStore } from 'store/configurations';
import { deviceStore } from 'store/device';
import { setDataAccounts } from 'store/modules/authenticate/authenticate.actions';


function SignInController({ token }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  let history = useHistory();
  const dispatch = useDispatch();

  const { setProperty: start } = configurationsStore.getState();
  const { setProperty: setPropertiedDevice, id: deviceId } = deviceStore.getState();

  const handleSignIn = async () => {
    try {
      if (!username || !password) {
        return alert('Preencha todos os campos');
      }
      setLoading(true);

      const response = await postSignIn({
        login: username.toLocaleLowerCase(),
        senha: password,
      });

      dispatch(setDataAccounts(response));

      history.push('/choose/company');
      setLoading(false);
    } catch (error) {
      console.log(error)
      setLoading(false);
      alert(
        'Ocorreu um erro ao realizar o login, verifique os dados digitados!'
      );
    }
  };

  const startBFF = async () => {
    try {
      start('environment', process.env.REACT_APP_ENV);

      setPropertiedDevice("id", deviceId || uuidv4());

      await getKey();

      await createDevice();

      setLoading(false);
    } catch (error) {
      alert(
        `Estamos passando por algumas instabilidades no momento, por favor tente acessar o BIB Rewards mais tarde.`
      );
    }
  };

  useEffect(() => {
    startBFF()
  }, [])

  const handlers = {
    handleSignIn,
    username,
    password,
    setUsername,
    setPassword,
    loading,
  };

  return <SignInScreen handlers={handlers} />;
}

const mapStateToProps = (state) => ({
  token: state.authenticate.token,
});
export default connect(mapStateToProps)(SignInController);
