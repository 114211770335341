import Button from '../../../../components/Button';
import InputLabel from '../../../../components/InputLabel';
import InputSelect from '../../../../components/InputSelect/InputSelect.component';
import { DateMask } from '../../../../utils/Masks/DateMask';
import { DocumentMask } from '../../../../utils/Masks/DocumentMask';
import { MoneyMask } from '../../../../utils/Masks/MoneyMask';
import { PhoneMask } from '../../../../utils/Masks/PhoneMask';
import {
  AddressInformations,
  BorderBottom,
  Container,
  Divider,
  InputRadio,
  LabelMedium,
  Row,
  Title,
} from './Form.styles';

import { getCep } from 'utils/ViaCep';

function FormComponent({ handlers }: { handlers: any }) {
  let {
    setTypeAddress,
    typeAddress,
    setMaritalStatus,
    clientName,
    setClientName,
    birthDate,
    setBirthDate,
    setGender,
    documentNumber,
    setDocumentNumber,
    motherName,
    setMotherName,
    email,
    setEmail,
    embossName,
    setEmbossName,
    addressHome,
    setAddressHome,
    addressWork,
    setAddressWork,
    addressHomeNumber,
    setAddressHomeNumber,
    addressNumberWork,
    setAddressNumberWork,
    setAddressHomecomplement,
    addressHomecomplement,
    setAddressWorkComplement,
    addressWorkComplement,
    district,
    setDistrict,
    districtWork,
    setDistrictWork,
    cityHome,
    setCityHome,
    cityWork,
    setCityWork,
    cityHomeCode,
    setCityHomeCode,
    cityCodeWork,
    setCityCodeWork,
    zipCode,
    setZipCode,
    zipCodeWork,
    setZipCodeWork,
    dddHome,
    setDddHome,
    phoneNumberHome,
    setPhoneNumberHome,
    dddCellPhone,
    setDddCellPhone,
    cellPhone,
    setCellPhone,
    creditLimit,
    setCreditLimit,
    valueIncome,
    setValueIncome,
    dayDue,
    setDayDue,
    handleCreateAccount,
  } = handlers;

  const optionsGender = [
    { title: 'Masculino', value: 'M' },
    { title: 'Feminino', value: 'F' },
    { title: 'Outros', value: 'O' },
  ];

  const optionsState = [
    { title: 'Casado(a)', value: 1 },
    { title: 'Solteiro(a)', value: 2 },
    { title: 'Viuvo(a)', value: 3 },
    { title: 'Divorciado(a)', value: 5 },
    { title: 'Outros', value: 6 },
  ];

  function handleDocumentNumber(text: string) {
    setDocumentNumber(DocumentMask(text));
  }

  function handleDayDue(text: string) {
    setDayDue(text.replace(/[^0-9.]/g, ''));
  }

  function handleBirthDate(text: string) {
    setBirthDate(DateMask(text));
  }

  function handleValueIncome(text: string) {
    setValueIncome(MoneyMask(text));
  }
  function handleCreditLimit(text: string) {
    setCreditLimit(MoneyMask(text));
  }

  function handleDdd(text: string) {
    setDddHome(text.replace(/[^0-9.]/g, ''));
  }

  function handlePhoneNumber(text: string) {
    setPhoneNumberHome(PhoneMask(text));
  }
  function handleDddCellPhone(text: string) {
    setDddCellPhone(text.replace(/[^0-9.]/g, ''));
  }

  function handleCellPhone(text: string) {
    setCellPhone(PhoneMask(text));
  }

  function handleHomeNumber(text: string) {
    typeAddress === 'R'
      ? setAddressHomeNumber(text.replace(/[^0-9.]/g, ''))
      : setAddressNumberWork(text.replace(/[^0-9.]/g, ''));
  }

  async function handleZipCode(text: string) {
    text = text.replace(/\D/g, '').replace(/^(\d{5})(\d{3})/g, '$1-$2');
    if (typeAddress === 'R') {
      setZipCode(text);
      if (text && text.length > 8) {
        try {
          const { logradouro, bairro, uf, localidade } = await getCep(text);
          setAddressHome(
            logradouro.length > 40 ? logradouro.substring(0, 40) : logradouro
          );
          setDistrict(bairro.length > 15 ? bairro.substring(0, 15) : bairro);
          setCityHomeCode(uf.length > 3 ? uf.substring(0, 3) : uf);
          setCityHome(
            localidade.length > 30 ? localidade.substring(0, 30) : localidade
          );
        } catch (error: any) {
          console.log('Error get zip code Via Cep', error);
        }
      }
    } else {
      setZipCodeWork(text);
      if (text && text.length > 8) {
        try {
          const { logradouro, bairro, uf, localidade } = await getCep(text);
          setAddressWork(logradouro);
          setDistrictWork(bairro);
          setCityCodeWork(uf);
          setCityWork(localidade);
        } catch (error: any) {
          console.log('Error get zip code Via Cep', error);
        }
      }
    }
    setZipCode(text);

    if (text && text.length > 8) {
      try {
        const { logradouro, bairro, uf, localidade } = await getCep(text);
        typeAddress === 'R'
          ? setAddressHome(logradouro)
          : setAddressWork(logradouro);
        typeAddress === 'R' ? setDistrict(bairro) : setDistrictWork(bairro);
        typeAddress === 'R' ? setCityHomeCode(uf) : setCityCodeWork(uf);
        typeAddress === 'R' ? setCityHome(localidade) : setCityWork(localidade);
      } catch (error: any) {
        console.log('Error get zip code Via Cep', error);
      }
    }
  }

  return (
    <Container>
      <InputLabel
        maxLength={14}
        value={documentNumber}
        onChange={handleDocumentNumber}
        label="CPF"
        type="text"
      />
      <InputLabel
        value={clientName}
        onChange={setClientName}
        label="Nome Completo"
        type="text"
        maxLength={40}
      />
      <InputLabel
        value={embossName}
        onChange={setEmbossName}
        label="Nome a ser impresso no cartão"
        type="text"
        maxLength={26}
      />
      <InputLabel
        value={motherName}
        onChange={setMotherName}
        label="Nome da mãe"
        type="text"
        maxLength={30}
      />
      <InputLabel
        value={dayDue}
        onChange={handleDayDue}
        label="Dia de vencimento do cartão"
        type="text"
        maxLength={2}
        disabled
      />
      <InputLabel
        value={creditLimit}
        onChange={handleCreditLimit}
        label="Limite de crédito"
        type="text"
        maxLength={15}
      />
      <Row>
        <InputLabel
          onChange={handleBirthDate}
          value={birthDate}
          label="Data de nascimento"
          type="text"
          maxLength={10}
        />
        <Divider />
        <InputSelect
          label="Gênero"
          options={optionsGender}
          onChange={setGender}
        />
      </Row>
      <Row>
        <InputSelect
          label="Estado Cívil"
          options={optionsState}
          onChange={setMaritalStatus}
        />

        <Divider />
        <InputLabel
          value={email}
          onChange={setEmail}
          label="Email"
          type="text"
          maxLength={60}
        />
      </Row>
      <Row>
        <InputLabel
          value={dddHome}
          onChange={handleDdd}
          inputStyle={{ width: '98%' }}
          label="DDD"
          type="text"
          maxLength={3}
        />
        <InputLabel
          value={phoneNumberHome}
          onChange={handlePhoneNumber}
          label="Telefone"
          type="text"
          maxLength={10}
        />
      </Row>
      <Row>
        <InputLabel
          value={dddCellPhone}
          onChange={handleDddCellPhone}
          inputStyle={{ width: '98%' }}
          label="DDD"
          type="text"
          maxLength={3}
        />
        <InputLabel
          value={cellPhone}
          onChange={handleCellPhone}
          label="Celular"
          type="text"
          maxLength={10}
        />
      </Row>
      <LabelMedium>Em qual endereço o cartão será entregue?</LabelMedium>
      <Row style={{ marginBottom: '15px' }}>
        <Row style={{ marginTop: '10px' }}>
          <InputRadio
            onChange={(e) => setTypeAddress(e.target.value)}
            type="radio"
            id="typeAddress"
            name="typeAddress"
            value="R"
          />
          <LabelMedium>Residêncial</LabelMedium>
          <InputRadio
            onChange={(e) => setTypeAddress(e.target.value)}
            style={{ marginLeft: '30px' }}
            type="radio"
            id="typeAddress"
            name="typeAddress"
            value="C"
          />
          <LabelMedium>Comercial</LabelMedium>
        </Row>
      </Row>
      {typeAddress && (
        <>
          <BorderBottom>
            <Title>
              Endereço {typeAddress === 'R' ? 'Residêncial' : 'Comercial'}
            </Title>
          </BorderBottom>

          <AddressInformations>
            <InputLabel
              value={zipCode}
              onChange={handleZipCode}
              label="CEP"
              type="text"
              maxLength={9}
            />
            <InputLabel
              label="Logradouro"
              type="text"
              onChange={typeAddress === 'R' ? setAddressHome : setAddressWork}
              value={typeAddress === 'R' ? addressHome : addressWork}
              maxLength={40}
            />
            <InputLabel
              value={
                typeAddress === 'R' ? addressHomeNumber : addressNumberWork
              }
              onChange={handleHomeNumber}
              label="Número"
              type="text"
              maxLength={6}
            />
            <InputLabel
              label="Complemento"
              type="text"
              onChange={
                typeAddress === 'R'
                  ? setAddressHomecomplement
                  : setAddressWorkComplement
              }
              value={
                typeAddress === 'R'
                  ? addressHomecomplement
                  : addressWorkComplement
              }
              maxLength={25}
            />
            <InputLabel
              value={typeAddress === 'R' ? district : districtWork}
              onChange={typeAddress === 'R' ? setDistrict : setDistrictWork}
              label="Bairro"
              type="text"
              maxLength={15}
            />
            <Row>
              <InputLabel
                value={typeAddress === 'R' ? cityHomeCode : cityCodeWork}
                onChange={
                  typeAddress === 'R' ? setCityHomeCode : setCityCodeWork
                }
                label="Estado"
                type="text"
                maxLength={3}
              />
              <Divider />
              <InputLabel
                value={typeAddress === 'R' ? cityHome : cityWork}
                onChange={typeAddress === 'R' ? setCityHome : setCityWork}
                label="Cidade"
                type="text"
                maxLength={30}
              />
            </Row>
          </AddressInformations>
        </>
      )}
      <Button onClick={handleCreateAccount}>Finalizar Solicitação</Button>
    </Container>
  );
}

export default FormComponent;
