import Loading from 'components/Loading/Loading.component';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { setConsultCard } from 'store/modules/companyCards/companyCards.actions';
import {
  consultCard,
  PostActivationCard,
  PostDeactivationCard,
  postDefinitiveBlock,
} from 'store/modules/companyCards/companyCards.endpoints';
import {
  setAccounts,
  setCurrentCardStatus,
  updateAccountStatus,
} from 'store/modules/relationship/relationship.actions';
import { getAccounts } from 'store/modules/relationship/relationship.endpoints';
import DeactiveCardScreen from './deactiveCard.screen';

interface ICurrentCard {
  uuid: string;
  last4Digits: string;
  cardsResult: Array<{ status: string; documentNumber: string }>;
}

interface IConsultCard {
  activeFlag: string;
  blockCode: string;
}
function DefinitiveBlockController({
  currentCard,
  companyCnpj,
  accountId,
  cardConsult,
  companyRelationcode,
}: {
  currentCard: ICurrentCard;
  companyCnpj: string;
  accountId: string;
  cardConsult: IConsultCard;
  companyRelationcode: string;
}) {
  const [blockingReason, setBlockingReason] = useState('');
  const [loading, setLoading] = useState(true);
  const [blocked, setBlocked] = useState(false);
  const [permanentBlock, setPermanentBlock] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    handleCurrentCard();
  }, []);

  const handleCurrentCard = async () => {
    if (
      (cardConsult.activeFlag === 'S' && cardConsult.blockCode) ||
      cardConsult.activeFlag === 'N'
    ) {
      setPermanentBlock(true);
      return setLoading(false);
    }

    if (currentCard?.cardsResult[0]?.status === 'Blocked') {
      setBlocked(true);
      return setLoading(false);
    }
    setBlocked(false);
    return setLoading(false);
  };

  const getDataAccount = async () => {
    try {
      const response = await getAccounts(companyCnpj, companyRelationcode);

      dispatch(setAccounts(response));
    } catch (error: any) {
      console.log('getDataAccount', error);
    }
  };

  const handleDeactivation = async () => {
    if (!blockingReason) {
      return alert('Por favor selecione o motivo da desativação do cartão.');
    }
    try {
      setLoading(true);
      const { uuid, last4Digits } = currentCard;

      if (blockingReason === 'BE') {
        await PostDeactivationCard(uuid, last4Digits);
      } else {
        const body = {
          last4digits: last4Digits,
          descriptionOccurrence: '',
          blockingReason,
          uuid,
        };
        await postDefinitiveBlock(body);

        const { data } = await consultCard(uuid, last4Digits);

        dispatch(setConsultCard(data));
      }

      alert('Cartão desativado com sucesso!');

      dispatch(setCurrentCardStatus('Blocked'));

      dispatch(updateAccountStatus(accountId, 'inactive'));

      setLoading(false);
      setBlocked(true);
    } catch (error: any) {
      alert('No momento seu cartão não está disponível para desativação.');
      return setLoading(false);
    }
  };
  const handleActivation = async () => {
    try {
      setLoading(true);
      const { uuid, last4Digits } = currentCard;
      await PostActivationCard(uuid, last4Digits);
      await getDataAccount();
      alert('Cartão ativado com sucesso!');
      dispatch(setCurrentCardStatus('Actived'));

      setLoading(false);
      setBlocked(false);
    } catch (error: any) {
      setLoading(false);
      return alert('No momento seu cartão não está disponível para ativação.');
    }
  };

  const handlers = {
    loading,
    setLoading,
    setBlockingReason,
    blockingReason,
    currentCard,
    blocked,
    handleDeactivation,
    handleActivation,
    permanentBlock,
  };

  return loading ? (
    <Loading size={40} icon={true} />
  ) : (
    <DeactiveCardScreen handlers={handlers} />
  );
}

const mapStateToProps = (state: any) => ({
  currentCard: state.relationship.currentCard,
  companyCnpj: state.company.cnpj,
  companyRelationcode: state.company.relationCode,
  accountId: state.relationship.currentAccount.accountNumber,
  cardConsult: state.companyCards.consultCard,
});
export default connect(mapStateToProps)(DefinitiveBlockController);
