import { api } from "utils/Api"

export const getPublicKey = async () => {
  try {
    const response = await api({}).get('/keys')

    return response
  } catch (error: any) {
    console.log('ERROR WHEN GET THE PUBLIC KEY ==> ', error)

    return error.response
  }
}
