import { cnpj as cnpjValidator } from 'cpf-cnpj-validator';
import { useState } from 'react';
import history from 'services/history';
import { postCreateCompany } from 'store/modules/company/company.endpoints';
import { maskCPFeCNPJ } from 'utils/Masks/DocumentMask';
import RegisterCompanyScreen from './RegisterCompany.screen';

function RegisterCompanyController() {
  const [razaoSocial, setRazaoSocial] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [nomeFantasia, setNomeFantasia] = useState('');
  const [loading, setLoading] = useState(false);

  const handleCreateCompany = async () => {
    try {
      if (!razaoSocial || !cnpj || !nomeFantasia) {
        return alert('Todos os campos devem ser preenchidos!');
      }

      const cnpjIsValid = cnpjValidator.isValid(cnpj);
      if (!cnpjIsValid) {
        return alert('CNPJ inválido, preencha o campo corretamente.');
      }

      setLoading(true);
      await postCreateCompany({ razaoSocial, cnpj, nomeFantasia });
      setLoading(false);
      alert('Empresa criada com sucesso!');
      history.push('/home/company/management');
    } catch (error: any) {
      setLoading(false);
      alert('Ocorreu um erro ao criar a empresa.');
    }
  };

  const handleCnpj = (cnpj: string) => {
    setCnpj(maskCPFeCNPJ(cnpj));
  };

  const handlers = {
    razaoSocial,
    setRazaoSocial,
    cnpj,
    handleCnpj,
    nomeFantasia,
    setNomeFantasia,
    loading,
    handleCreateCompany,
  };

  return <RegisterCompanyScreen handlers={handlers} />;
}

export default RegisterCompanyController;
