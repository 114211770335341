import { getPublicKey } from "services/key"
import { keyStore } from "store/key"

export const getKey = async () => {
  try {
    const response = await getPublicKey()

    const { setProperty } = keyStore.getState()
    setProperty('public', response.data)

    return response.data
  } catch (error: any) {
    console.log('ERROR GET PUBLICK KEY ==> ', error)

    return ''
  }
}
