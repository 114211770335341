export const beautifyJSON = (obj: unknown) => {
  return JSON.stringify(obj, null, '\t')
}

export const log = (
  detailText: object | string,
  titleText = '',
  type: 'default' | 'info' | 'warning' | 'success' | 'error' = 'default',
  badgeText = 'log',
  enabledLog = false,
) => {
  if (!enabledLog) {
    return
  }

  if (typeof detailText === 'object') {
    detailText = beautifyJSON(detailText)
  }

  // Define a cor do badge que vai aparecer no log.
  let badgeColor = ''
  let textColor = ''

  switch (type) {
    case 'info':
      badgeColor = '#0288D1'
      textColor = 'white'
      break
    case 'warning':
      badgeColor = '#FFC107'
      textColor = 'white'
      break
    case 'success':
      badgeColor = '#4CAF50'
      textColor = 'white'
      break
    case 'error':
      badgeColor = '#FF5252'
      textColor = 'white'
      break
    default:
      badgeColor = '#FEFEFE'
      textColor = 'black'
  }

  const badgeStyle = `background: ${badgeColor}; color: ${textColor}; font-weight: bold; text-transform: uppercase; padding: 4px 8px; border-radius: 6px;`
  const titleStyle = 'font-weight: bold'
  const detailStyle = 'font-weight: regular'

  const text = `%c${badgeText || type}%c${' ' + titleText}\n%c${detailText || ''
    }`

  console.log(text, badgeStyle, titleStyle, detailStyle)
}
