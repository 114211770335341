import axios, { AxiosRequestConfig } from 'axios'
import { Buffer } from 'buffer'
import { authorizationStore } from 'store/authorization'
import { configurationsStore } from 'store/configurations'
import { deviceStore } from 'store/device'
import { keyStore } from 'store/key'
import { decryptAES, encryptAES, encryptRSA, generateRandomString } from 'utils/Crypto'
import { beautifyJSON, log } from 'utils/Logs'
import { v4 as uuidv4 } from 'uuid'

type APIType = {
  needAuthentication?: boolean
}

export const api = ({ needAuthentication }: APIType) => {
  const { FID, environment } = configurationsStore.getState()
  const {
    cipherAES: sessionCipher,
    tokenAES: sessionToken,
    public: publicKey,
  } = keyStore.getState()

  const ENABLE_E2EE = true
  const baseURL = `https://${environment === 'prod' ? '' : 'sandbox-'
    }app-biabox-bff.stefanini-spark.com`

  const requester = axios.create({ baseURL })

  // Identificador que será usado para identificar as chamadas
  const requestId = generateRandomString(12).toUpperCase()

  const requestInterceptor = async (reqConfig: AxiosRequestConfig) => {
    const { headers, baseURL, method, url, data } = reqConfig
    const { id: deviceId } = deviceStore.getState()

    reqConfig.headers = {
      ...headers,
      ...{
        'Content-Type': 'application/json',
        'x-fid': FID,
        'x-device-id': deviceId,
        'x-env': environment,
        'x-request-id': uuidv4(),
      },
    }

    const { authorization } = authorizationStore.getState()
    console.log('authorization ==> ', authorization)
    if (authorization !== null && authorization?.length > 0) {
      reqConfig.headers = {
        ...reqConfig.headers,
        Authorization: `Bearer ${authorization}`,
      }
    }

    const useHeader =
      method?.toLocaleLowerCase() === 'get' ||
      method?.toLocaleLowerCase() === 'delete'

    // caso for uma autenticação de dois fatores
    if (needAuthentication) {
      reqConfig.headers = {
        ...reqConfig.headers,
        'x-authentication': useHeader
          ? reqConfig.headers.authentication
          : data.authentication,
      }

      useHeader
        ? delete reqConfig.headers.authentication
        : delete data.authentication
    }

    const logObj = {
      baseURL,
      url,
      method,
      headers: reqConfig.headers,
      data,
      decrypted: {},
    }

    // exclui o campo authentication do body
    if (needAuthentication) {
      method?.toLocaleLowerCase() === 'post' &&
        delete reqConfig.data.authentication
    }

    // Verifica qual criptografia deve ser usada
    let payload = reqConfig.data

    const useRSA = reqConfig.url === '/devices'

    const isPublicKey = reqConfig.url?.includes('/keys')
    const useEncrypt =
      ENABLE_E2EE && !isPublicKey && method !== 'get' && method !== 'delete'

    if (useRSA) {
      if (useEncrypt) {
        if (!publicKey) {
          Promise.reject(new Error('Invalid key for encryption (E00001)'))
        }

        payload = await encryptRSA(publicKey!, reqConfig.data)
      }
    } else {
      if (useEncrypt) {
        const encrypted = await encryptAES(
          sessionToken!,
          reqConfig.data,
          sessionCipher!,
        )

        payload = Buffer.from(encrypted, 'base64').toString('hex')
      }
    }

    if (useEncrypt) {
      logObj.data = { payload }
      logObj.decrypted = reqConfig.data
    }

    log(
      beautifyJSON(logObj),
      `${baseURL && baseURL + url} (${requestId})`,
      'info',
      'request',
      environment === 'sandbox',
    )

    if (useEncrypt) {
      reqConfig.data = {
        payload,
      }
    }

    return reqConfig
  }

  // Create request interceptor
  requester.interceptors.request.use(async (config) => {
    return new Promise((resolve, reject) => {
      requestInterceptor(config).then(
        () => {
          resolve(config)
        },
        (error) => {
          reject(error)
        },
      )
    })
  })

  // Create response interceptor
  requester.interceptors.response.use(
    async (response) => {
      const { status, data, config } = response
      const { headers, baseURL, method, url } = config

      const logObj = {
        baseURL,
        url,
        method,
        headers,
        data,
        decryted: {},
      }

      const isPublicKey = url?.includes('/keys')
      const useEncrypt = ENABLE_E2EE && !isPublicKey
      try {
        if (useEncrypt && !!response.data.payload) {
          const payload = Buffer.from(response.data.payload, 'hex').toString(
            'base64',
          )

          const decrypted = await decryptAES(
            sessionToken!,
            payload,
            sessionCipher!,
          )

          response.data = JSON.parse(decrypted)
          logObj.decryted = JSON.parse(decrypted)
        }

        log(
          beautifyJSON(logObj),
          `${(baseURL || '') + url} (${requestId})`,
          'success',
          `response ${status}`,
          environment === 'sandbox',
        )
      } catch (err) {
        console.log('decrypt error ===> ', err)
      }

      return response
    },
    async (error) => {
      const { response } = error
      const { status, data, config } = response
      const { headers, baseURL, method, url } = config

      const logObj: any = {
        baseURL,
        url,
        method,
        headers,
        data,
      }

      try {
        const isPublicKey = url?.includes('/keys')

        if (ENABLE_E2EE && !isPublicKey && !!response.data.payload) {
          const payload = Buffer.from(response.data.payload, 'hex').toString(
            'base64',
          )

          const decrypted = await decryptAES(
            sessionToken!,
            payload,
            sessionCipher!,
          )

          response.data = JSON.parse(decrypted)
          logObj.decryted = JSON.parse(decrypted)
        }

        log(
          beautifyJSON(logObj),
          `${(baseURL || '') + url} (${requestId})`,
          'error',
          `response ${status}`,
          environment === 'sandbox',
        )
      } catch (err) {
        console.log('decrypt error ===> ', JSON.stringify(err))
      }

      return Promise.reject(error)
    },
  )

  return requester
}
