import { postActivateCard, postCreateCard } from './companyCards.endpoints';
import { ICards, ICreateCard, IDisableCard } from './companyCards.interface';

export const setActivateCard = (data: ICards) => {
  return { type: '@companyCards/ACTIVATECARD', data };
};

export const setDisableCard = (data: IDisableCard) => {
  return { type: '@companyCards/DISABLECARD', data };
};

export const fetchActivateCard = (data: ICards) => {
  return async (dispatch: any) => {
    try {
      const response = await postActivateCard(data);
      return response;
    } catch (error: any) {
      return 'error';
    }
  };
};

export const fetchCreateCard = (data: ICreateCard) => {
  return async (dispatch: any) => {
    try {
      const response = await postCreateCard(data);
      return response;
    } catch (error: any) {
      return 'error';
    }
  };
};

export const setConsultCard = (consult: any) => {
  return { type: '@companyCards/SET_CONSULT_CARD', consult };
};
