import Loading from 'components/Loading/Loading.component';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  getAccountCards,
  getAuthorizationHistory,
  getInvoicesDueDate,
  getInvoicesTransactions,
} from 'store/modules/companyCards/companyCards.endpoints';
import AuthorizationHistoryScreen from './AuthorizationHistory.screen';
import {
  CardTransactionType,
  InvoiceDueDateType,
  InvoiceTransactionsResponse,
} from './AutorizationHistory.types';

function AuthorizationHistoryController({ currentCard }: { currentCard: any }) {
  const [allCardsTransactions, setAllCardsTransactions] = useState([
    { card: { last4digits: '' }, register: [] },
  ]);
  const [loading, setLoading] = useState(true);
  const { last4Digits, uuid } = currentCard;

  useEffect(() => {
    fetchAllTransactions();
  }, []);

  const fetchAllTransactions = async () => {
    try {
      setLoading(true);
      const response = await getAccountCards(uuid);

      const cardsResult = response.data.cardsResult;

      if (!cardsResult || cardsResult.length === 0) return;

      const cardsTransactions: Array<CardTransactionType> = cardsResult.map(
        (item: CardTransactionType) => {
          return {
            card: item,
            register: [],
          };
        }
      );

      await getHistory(cardsTransactions);
      await getAllInvoices(cardsTransactions);
    } catch (error: any) {
      setLoading(false);
      alert(
        'Falha ao carregar histórico de autorizações. Por favor tente novamente mais tarde.'
      );
    }
  };

  const getHistory = async (cardsTransactions: any) => {
    try {
      const processAllAutorizations = cardsTransactions.map(
        async (item: any) => {
          const response = await getAuthorizationHistory(
            item.card.last4digits,
            item.card.uuid
          );

          return {
            register: [...response],
            last4digits: item.card.last4digits,
            uuid: item.card.uuid,
          };
        }
      );

      const allAutorizationsHistories = await Promise.all(
        processAllAutorizations
      );

      allAutorizationsHistories.map((item: any) => {
        const { last4digits } = item;
        if (item.register?.length > 0) {
          item.register.map((resg: any) => {
            cardsTransactions.map((cardsTransaction: any) => {
              if (cardsTransaction.card.last4digits !== last4digits) return;
              const transaction = {
                date: resg.tmsInclH,
                numAuthorization: resg.numAuthorization,
                establishmentName: resg.establishmentName,
                qtdParcel: resg.qtdParcel,
                value: `${coinType(resg.coinCode)} ${resg.mainValue}`,
                status: verifyStatus(
                  resg.descResponseTransaction,
                  resg.descDrop
                ),
                isAutorization: true,
              };
              cardsTransaction.register.push(transaction);
            });
          });
        }
      });

      setAllCardsTransactions(cardsTransactions);
      setLoading(false);

    } catch (error: any) {
      setLoading(false);
      return false;
    }
  };

  const getAllInvoices = async (cardsTransactions: any) => {
    let currentInvoiceDueDates: InvoiceDueDateType[] = [
      {
        invoiceDueDate: '',
      },
    ];

    try {
      const { data } = await getInvoicesDueDate(uuid, last4Digits);

      if (data) {
        const closedInvoiceDueDates = data.listInvoiceDueDate.slice(0, 3);
        currentInvoiceDueDates.push(...closedInvoiceDueDates);
      }

    } catch (error: any) { }

    const fetchInvoiceTransactionsResponse = currentInvoiceDueDates.map((item) => getInvoicesTransactions(uuid, last4Digits, item.invoiceDueDate));

    const fetchInvoiceTransactionsResponseAll = await Promise.all(
      fetchInvoiceTransactionsResponse
    );

    const currentInvoiceTransactions: InvoiceTransactionsResponse[] = [];

    fetchInvoiceTransactionsResponseAll.map((item) => {
      if (typeof item !== 'boolean' && !!item)
        currentInvoiceTransactions.push(item);
    });

    if (
      !currentInvoiceTransactions ||
      currentInvoiceTransactions.length === 0
    )
      return false;

    currentInvoiceTransactions.map((invoiceTransactions) => {
      invoiceTransactions.transaction.map((item) => {
        const { numCard } = item;
        const last4digits = numCard.slice(numCard.length - 4);

        const allTransactions = cardsTransactions;

        allTransactions.map((cardsTransaction: any) => {
          if (cardsTransaction.card.last4digits !== last4digits) return;

          const {
            description,
            valueTransactionInternational,
            valueTransactionNational,
            date,
            numParcelOf,
            abbreviationGroup,
          } = item;

          const formattedNationalValue = handleFormatCardTransactionValue(
            valueTransactionNational
          );

          const formattedInternationalValue = handleFormatCardTransactionValue(
            valueTransactionInternational
          );

          let value = formattedNationalValue;

          if (formattedInternationalValue !== '0.00')
            value = formattedInternationalValue;

          const transaction = {
            date: date,
            numAuthorization: '',
            establishmentName: description,
            qtdParcel: numParcelOf,
            value: `R$ ${value}`,
            status: 'APROVADA',
            abbreviationGroup,
            isAutorization: false,
          };

          cardsTransaction.register.push(transaction);
        });

        handleCardsTransactionsSort(allTransactions);

        setAllCardsTransactions(allTransactions);
      });
    });
    setLoading(false);
  };

  const handleFormatCardTransactionValue = (value: string): string => {
    return value.replace('-', '');
  };

  const verifyStatus = (descResponseTransaction: string, descDrop: string) => {
    if (descDrop === 'NEGADA') {
      return 'NEGADA';
    }
    if (descDrop === 'ONLINE' || descDrop === 'CANCELADA') {
      return 'CANCELADA';
    }
    if (
      descDrop === 'NORMAL' ||
      (descResponseTransaction === 'NAO VALIDOU SENHA OFFLINE' &&
        descDrop !== 'CANCELADA')
    ) {
      return 'APROVADA';
    }

    return descResponseTransaction;
  };

  const coinType = (coinCode: string) => {
    switch (coinCode) {
      case '986':
        return 'R$';
      case '840':
        return 'US$';
      case '978':
        return '€';
      default:
        return '';
    }
  };

  const handleCardsTransactionsSort = (
    cardsTransactions: CardTransactionType[]
  ) => {
    cardsTransactions.map((transaction) => {
      transaction.register = transaction.register.sort((a: any, b: any) => {
        return b.date.diff(a.date);
      });
    });

    return cardsTransactions;
  };

  const handlers = {
    allCardsTransactions,
  };

  return (
    <>
      {loading ? (
        <Loading size={40} icon={true} />
      ) : (
        <AuthorizationHistoryScreen handlers={handlers} />
      )}
    </>
  );
}

const mapStateToProps = (state: any) => ({
  currentCard: state.relationship.currentCard,
});
export default connect(mapStateToProps)(AuthorizationHistoryController);
