import { create } from "zustand";

const initialState = {
  authententication: "",
  authorization: "",
};

export const authorizationStore = create()((set) => {
  return {
    // state
    ...initialState,

    // actions
    setProperty: (state, value) => {
      set(() => ({ [state]: value }));
    },
    reset: () => {
      set(initialState);
    },
  };
});
