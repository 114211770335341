import React from 'react';
import {
  Container,
  Label,
  Header,
  Separator,
  Title,
  Icon,
  Left,
  Right,
  Spacement,
} from './ListCompanys.styles';
import iconEdit from 'assets/icons/edit.png';
import iconTrash from 'assets/icons/trash.png';
import PopUp from '../PopUp/PopUp.component';

function ListCardsComponent({ handlers }: { handlers: any }) {
  const { companys, handleEdit, handleDeleteCompany } = handlers;

  return (
    <>
      <Header>
        <Separator>
          <Title>Empresa</Title>
        </Separator>
        <Separator>
          <Title>Razão Social</Title>
        </Separator>
        <Separator>
          <Title>CNPJ</Title>
        </Separator>
        <Separator>
          <Title>Status</Title>
        </Separator>
        <Separator>
          <Title>Opções</Title>
        </Separator>
      </Header>
      {companys?.map((company: any, index: string) => {
        return (
          <div key={index}>
            <ListCard
              handleDeleteCompany={handleDeleteCompany}
              handleEdit={handleEdit}
              company={company}
            />
          </div>
        );
      })}
    </>
  );
}

function ListCard({
  company,
  handleEdit,
  handleDeleteCompany,
}: {
  company: any;
  handleEdit: any;
  handleDeleteCompany: any;
}) {
  const borderRight = { borderRight: '1px solid #2C2C2C', height: '13px' };
  const cnpj = company?.cnpj.replace(
    /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
    '$1.$2.$3/$4-$5'
  );
  return (
    <Container>
      <Separator style={borderRight}>
        <Label>{company?.nomeFantasia}</Label>
      </Separator>
      <Separator style={borderRight}>
        <Label>{company?.razaoSocial}</Label>
      </Separator>
      <Separator style={borderRight}>
        <Label>{cnpj}</Label>
      </Separator>
      <Separator style={borderRight}>
        <Label>{company?.status}</Label>
      </Separator>
      <Separator>
        <Spacement />
        <Left>
          <Icon onClick={() => handleEdit(company)} src={iconEdit} />
        </Left>
        <Right>
          <PopUp
            companyName={company?.nomeFantasia}
            handleDeleteCompany={() => handleDeleteCompany(company.cnpj)}
          />
        </Right>
        <Spacement />
      </Separator>
    </Container>
  );
}

export default ListCardsComponent;
