import { create } from "zustand";

const initialState = {
  public: "",
  cipherAES: "",
  tokenAES: "",
};

export const keyStore = create()((set) => {
  return {
    // state
    ...initialState,

    // actions
    setProperty: (state, value) => {
      set(() => ({ [state]: value }));
    },
  };
});
